<template>
  <div>
    <multiselect v-model="selected" :options="options" :placeholder="placeholder" label="name" track-by="name"
                 searchable @search-change="load">
      <span slot="noResult">
        Don't see your integration partner? <a data-paperform-id="sokrzmpf" data-popup-button="1" href="#"
                                               class="not-found">Click here to invite them</a>
      </span>
    </multiselect>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },
  props: ['value', 'placeholder', 'prefetch'],
  data () {
    return {
      selected: null,
      search: null,
      options: []
    }
  },
  watch: {
    value () {
      this.reset()
    },
    selected () {
      this.$emit('input', this.selected)
    }
  },
  created () {
    this.reset()
    if (this.prefetch) {
      this.prefetchPage(1)
    }
  },
  methods: {
    ...mapActions(['fetchProducts']),
    prefetchPage (page) {
      this.fetchProducts({ sort: 'name', per_page: 100, page }).then(res => {
        if (res.data.data.length) {
          this.options = [...this.options, ...res.data.data]
          if (!this.search) window.setTimeout(() => this.prefetchPage(page + 1), 1000)
        }
      })
    },
    reset () {
      if (this.selected !== this.value) this.selected = this.value
    },
    load (query) {
      this.search = query
      if (query) {
        this.fetchProducts({ search: query }).then(res => (this.options = res.data.data))
      } else {
        this.options = []
      }
    }
  }
}
</script>
